import { Component, OnInit, ViewChild } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { Swal2Service } from 'src/app/servicios/swal2.service';
import { TrabajaNosotrosService } from 'src/app/servicios/trabaja-nosotros.service';
import { environment } from 'src/environments/environment';
import { DetalleTrabajaNosotrosService } from 'src/app/servicios/detalle-trabaja-nosotros.service';
import { TokenStorageService } from 'src/app/shared/services/token.service';
import {MatAccordion} from '@angular/material/expansion';
import { DatePipe } from '@angular/common'
//import {MatExpansionModule} from '@angular/material';
//import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-trabaja-nosotros',
  template: `<section> <div class="container-fluid">
  <!-- <div class="row"> <div class="col-md-12"> <app-container-page class="mat-elevation-z2" titulo="Lista de Convocatorias" [recargar]="false" >
  <app-table-material [data]="datos" [columnas]="columnas" [slots]="slots"> </app-table-material> </app-container-page> </div>
  </div>-->
  <div class="row"> <p> <mat-toolbar color="primary"> <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" >
  <mat-icon>menu</mat-icon> </button> <span>LISTA DE CONVOCATORIAS</span> <span class="example-spacer"></span> <button class="mx-3" matTooltip="Refrescar" mat-mini-fab color="warn" (click)="listTrabajaNosotros()" > <mat-icon>autorenew</mat-icon> </button> </mat-toolbar> </p>
  <!-- <div class="col-lg-3 col-md-12 col-sm-12 order-2 order-lg-1 order-md-2"> <div class="p-2"> <app-container-page class="mat-elevation-z2" titulo="BÚSQUEDA" [recargar]="false"> <ng-container slot="btnAcciones"> <button class="mx-3" matTooltip="Buscar" mat-mini-fab color="primary"> <mat-icon>search</mat-icon> </button> </ng-container> </app-container-page> <form style="font-size: small; padding-top: 2vh;"> <mat-form-field appearance="fill"> <mat-label>Nombre o Descripción de la Convocatoria</mat-label> <textarea matInput row="5"></textarea> </mat-form-field> <mat-form-field appearance="fill"> <mat-label>Seleccion Rango de Fecha</mat-label> <mat-date-range-input [formGroup]="range" [rangePicker]="picker"> <input matStartDate formControlName="start" placeholder="Fecha Publicación" /> <input matEndDate formControlName="end" placeholder="Fecha Vigencia" /> </mat-date-range-input> <mat-datepicker-toggle matSuffix [for]="picker" ></mat-datepicker-toggle> <mat-date-range-picker #picker> <mat-date-range-picker-actions> <button mat-button matDateRangePickerCancel>Cancelar</button> <button mat-raised-button color="primary" matDateRangePickerApply > Aplicar </button> </mat-date-range-picker-actions> </mat-date-range-picker> </mat-form-field> <mat-form-field appearance="fill"> <mat-label>Etapa Resultados</mat-label> <mat-select> <mat-option value="option1">Evaluación</mat-option> <mat-option value="option2">Conocimiento</mat-option> <mat-option value="option3">Final</mat-option> </mat-select> </mat-form-field> </form> </div>
  </div> -->
  <div class="col-lg-12"> <div class="p-2">
  <!-- <app-table-material [data]="datos" [columnas]="columnas" [slots]="slots" buscador="false" >
  </app-table-material> -->


  <div class="row" style="margin-bottom: 25px;">
  <div class="col-lg-2">
  <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Fecha de Inicio</mat-label>
                            <input
                              matInput
                              type="date"
                              [(ngModel)]="dIni"
                              required
                            />
  </mat-form-field>
  </div>
  <div class="col-lg-2">
  <mat-form-field appearance="fill" class="w-100">
                            <mat-label>Fecha Final</mat-label>
                            <input
                              matInput
                              type="date"
                              [(ngModel)]="dFin"
                              required
                            />
  </mat-form-field>
  </div>
  <div class="col-lg-6">
     <mat-form-field hideRequiredMarker floatLabel='auto' appearance='legacy' color='accent'>
        <mat-label>Ingrese un valor de búsqueda</mat-label>
        <input matInput [(ngModel)]="cBus">
        <mat-hint align='end'>Buscador de Convocatorias</mat-hint>
        </mat-form-field>
    </div>
    <div class="col-lg-2">
    <button mat-fab (click)="listTrabajaNosotros()">Buscar</button>
    </div>
  </div>


  <mat-accordion class="example-headers-align" multi="true" hideToggle >
    <mat-expansion-panel class="disabled-pointer"  style="padding-bottom: 20px;" *ngFor="let item of datos;"  [(expanded)]="item.estadoPanel">
      <mat-expansion-panel-header>
        <mat-panel-title style="margin-right: 0px;">
        <table width="100%">
          <tr style="background-color: #3f51b5;color: white;">
            <td colspan="3">{{item.cConvTitulo}}</td>
            <td style="Word-break: inherit; padding-left: 5px; padding-right: 5px;">Visita</td>
            <td style="Word-break: inherit; padding-left: 5px; padding-right: 5px;">Estado</td>
            <td>Acciones</td>
        </tr>
          <tr style="background-color: #66bbd9;">
            <td colspan="3" style="Word-break: inherit;"><p>{{item.cConvDescripcion}}</p></td>
            <td>{{item.iConvVisitas}}</td>
            <td>{{item.cEstado}}</td>
            <td>
              <button  class="btn-acordion-toggle" mat-flat-button color="primary" (click)="togglePanel(item.estadoPanel)">{{item.estadoPanel ? 'Ocultar etapas' : 'Ver etapas'}}
                <mat-icon *ngIf="!item.estadoPanel">expand_more</mat-icon>
                <mat-icon *ngIf="item.estadoPanel">expand_less</mat-icon>
              </button>
            </td>
          </tr>
          <tr style="background-color: #66bbd9;">
            <td colspan="4">
              <ul>
                <li *ngFor="let itemC of item.json_convocatorias">
                  {{itemC.name_title}}
                  <a class="btn-acordion-toggle" href="{{ backend }}/{{itemC.archivo}}" target=”_blank”
                     (click)="accionBtnItemTrabajas_(item.iConvId)">
                    <mat-icon style="color:red;" *ngIf="itemC.extension=='pdf'">picture_as_pdf</mat-icon>
                    <mat-icon style="color:orange;" *ngIf="itemC.extension=='jpg'">image</mat-icon>
                    <mat-icon style="color:orange;" *ngIf="itemC.extension=='jpeg'">image</mat-icon>
                    <mat-icon style="color:orange;" *ngIf="itemC.extension=='png'">image</mat-icon>
                    <mat-icon style="color:blue;" *ngIf="itemC.extension=='doc'">insert_drive_file</mat-icon>
                    <mat-icon style="color:blue;" *ngIf="itemC.extension=='docx'">insert_drive_file</mat-icon>
                    <mat-icon style="color:green;" *ngIf="itemC.extension=='xls'">insert_drive_file</mat-icon>
                    <mat-icon style="color:green;" *ngIf="itemC.extension=='xlsx'">insert_drive_file</mat-icon>
                    ({{itemC.extension}})
                  </a>
                </li>
              </ul>
            </td>
            <td></td>
            <td></td>
          </tr>
          <tr style="background-color: #66bbd9;">
            <td>Publicado el: {{item.dConvFechaPublicacion}}</td>
            <td style="background-color: #66bbd9;">Publicado por: {{item.cNombres}} </td>
            <td style="background-color: #66bbd9;">Última actualizacion: {{item.dUltimaActualizacion}}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </table>
        </mat-panel-title>
        <!--
        <mat-panel-description style="margin-right: 0px; background-color: #3f51b5; color: white;">
        Visitas:<br> {{item.iConvVisitas}} </mat-panel-description>
        <mat-panel-description style="background-color: #3f51b5;color: white;">
        Estado:<br> Activo </mat-panel-description>-->
        </mat-expansion-panel-header>

        <table *ngFor="let itemD of item.json_detalle_convocatorias" style="margin-top: 5px;">
        <tr  style="background-color: #e25c27;color: white;"><td colspan="3">{{itemD.cTipoEtapa}} - {{itemD.cDetaConvTitulo}}</td><td style="Word-break: inherit; padding-left: 5px; padding-right: 5px;">Visitas</td></tr>
        <tr style="background-color: #e25c277d;"><td colspan="3" style="Word-break: inherit;"><p>{{itemD.cDetaConvDescripcion}}</p></td><td>{{itemD.iDetaConvVisitas}}</td></tr>
        <tr style="background-color: #e25c277d;"><td colspan="4">

        <ul>
      <li *ngFor="let itemArc of itemD.archivos">
      {{itemArc.name_title}}
      <a  class="btn-acordion-toggle" href="{{ backend }}/{{itemArc.archivo}}" target=”_blank” (click)="accionBtnItemDetalleTrabajas_(itemD.iDetaConvId)">
      <mat-icon style="color:red;" *ngIf="itemArc.extension=='pdf'">picture_as_pdf</mat-icon>
      <mat-icon style="color:orange;" *ngIf="itemArc.extension=='jpg'">image</mat-icon>
      <mat-icon style="color:orange;" *ngIf="itemArc.extension=='jpeg'">image</mat-icon>
      <mat-icon style="color:orange;" *ngIf="itemArc.extension=='png'">image</mat-icon>
      <mat-icon style="color:blue;" *ngIf="itemArc.extension=='doc'">insert_drive_file</mat-icon>
      <mat-icon style="color:blue;" *ngIf="itemArc.extension=='docx'">insert_drive_file</mat-icon>
      <mat-icon style="color:green;" *ngIf="itemArc.extension=='xls'">insert_drive_file</mat-icon>
      <mat-icon style="color:green;" *ngIf="itemArc.extension=='xlsx'">insert_drive_file</mat-icon>
      ({{itemArc.extension}})
      </a>
      </li>
      </ul>

        </td></tr>
        <tr style="background-color: #e25c277d;"><td>Publicado el: {{itemD.dDetaConvFechaPublicacionWeb}}</td><td>Publicado por: {{itemD.name}} </td><td>Última actualizacion: {{itemD.dDetaConvUltActualizacion}}</td><td></td></tr>

        </table>




      <mat-action-row>
      <a
      mat-icon-button
      color="primary"
      aria-label="Imprimir"
      class="btn-acordion-toggle"
      target="_blank"
      (Click)="print();"
      >
      <mat-icon> local_printshop</mat-icon>
      </a>
      </mat-action-row>
    </mat-expansion-panel>


</mat-accordion>


<!--
  <app-tabla-expandible-material [data]="datos" [columnas]="columnas" [slots]="slots" (accionBtnItem)="accionBtnItemConvocatorias($event)" >


  <ng-container  matColumnDef="cConvDescripcion">
    <mat-header-cell *matHeaderCellDef>Policy Number</mat-header-cell>
    <mat-cell *matCellDef="let policy">hkhk</mat-cell>
  </ng-container>

  <ng-container slot="expandidocontent">

  <app-table-material [data]="datosDetalle" [columnas]="columnasdetalle" color="black" buscador="false" (accionBtnItem)="accionBtnItemDetalleTrabajas($event)" >
  </app-table-material> </ng-container>
  </app-tabla-expandible-material>
-->

  </div> </div> </div> </div></section>`,
  styles: [`.mat-expansion-panel-header {
    display: contents;
  }

  .example-form-field {
    margin-right: 20px;
  }

  mat-card {
    max-width: 400px;
    margin: 2em auto;
    text-align: center;
  }

  mat-form-field {
    display: block;
  }

  .custom {
    max-width: 280px;
    width: 95%;
    margin: 10px;
    left: 20px;
  }

  .titleMod {
    font-size: 18px;
    font-weight: bold;
    color: #0000ff;
  }

  .mat-list-item {
    max-height: 25px;
  }

  #tableprincipal {
    width: 100%;
    padding: 0px;
    width: 100%;
    max-height: calc(100vh - 300px);
    overflow: auto;
  }

  .mat-form-field {
    font-size: 11px;
    padding: 0 1rem 0 1rem;
    width: 100%;
  }

  .mat-row:hover {
    background: #d7ebfa;
    cursor: pointer;
  }

  .example-spacer {
    flex: 1 1 auto;
  }

  table {
    width: 100%;
    font-size: small
  }

  section {
    display: grid;
  }

  td {
    text-overflow: ellipsis;
    Word-break: break-all;
  }

  tr:hover {
    background: #f4f7fa;
    cursor: pointer;
  }

  .disabled-pointer {
    pointer-events: none;
  }

  .btn-acordion-toggle {
    pointer-events: auto;
  }

  `]
})
export class TrabajaNosotrosComponent implements OnInit {


  backend = environment.backendStorage;
  isLoggedIn = false;
  username: any;
  dIni: any;
  dFin: any;
  cBus: any;
  datos: any = [];
  datos2: any = [];
  datosDetalle: any = [];
  form!: FormGroup;
  formDetalle!: FormGroup;
  range;
  dataSource:any;

  columnas = [
    {
      titulo: 'Nombre de Convocatoria',
      value: 'cConvTitulo',
      text: true,
      width: '15%',
      text_align: 'justify',
      pdf: false,
    },
    {
      titulo: 'Descripción',
      value: 'cConvDescripcion',
      text: true,
      width: '30%',
      text_align: 'justify',
      pdf: false,
    },
    {
      titulo: 'Visitas',
      value: 'iConvVisitas',
      text: true,
      width: '5%',
      text_align: 'center',
      pdf: false,
    },

    {
      titulo: 'Publicado por:',
      value: 'cNombres',
      text: true,
      width: '15%',
      text_align: 'justify',
      pdf: false,
    },
    {
      titulo: 'Fecha de Publicación',
      value: 'dConvFechaPublicacionWeb',
      text: true,
      width: '10%',
      text_align: 'center',
      pdf: false,
    },
    {
      titulo: 'Fecha Vigencia',
      value: 'dConvFechaVigenciaWeb',
      text: true,
      width: '10%',
      text_align: 'center',
      pdf: false,
    },
    {
      titulo: 'Archivo',
      value: 'cConvArchivo',
      text: true,
      width: '10%',
      text_align: 'center',
      pdf: true,
    },

    {
      titulo: 'Acciones',
      value: 'acciones',
      slot: true,
      center: true,
      width: '5%',
      text_align: 'center',
      pdf: false,
    },
  ];

  // columnas = [

  //   {
  //     titulo: 'Nombre de Convocatoria',
  //     value: 'cConvTitulo',
  //     width: '15%',
  //     text: 'justify',
  //     pdf: false,
  //   },
  //   {
  //     titulo: 'Descripción',
  //     value: 'cConvDescripcion',
  //     width: '40%',
  //     text: 'justify',
  //     pdf: false,
  //   },
  //   {
  //     titulo: 'Visitas',
  //     value: 'iConvVisitas',
  //     width: '5%',
  //     text: 'center',
  //     pdf: false,
  //   },

  //   {
  //     titulo: 'Publicado por:',
  //     value: 'cNombres',
  //     width: '15%',
  //     text: 'center',
  //     pdf: false,
  //   },
  //   {
  //     titulo: 'Fecha de Publicación',
  //     value: 'dConvFechaPublicacion',
  //     width: '10%',
  //     text: 'center',
  //     pdf: false,
  //   },
  //   {
  //     titulo: 'Fecha Vigencia',
  //     value: 'dConvFechaVigencia',
  //     width: '10%',
  //     text: 'center',
  //     pdf: false,
  //   },
  //   {
  //     titulo: 'Archivo',
  //     value: 'cConvArchivo',
  //     width: '5%',
  //     text: 'center',
  //     pdf: true,
  //   },
  //   {
  //     titulo: 'Acción',
  //     value: 'slot',
  //     width: '5%',
  //     text: 'center',
  //     pdf: false

  //   },
  // ];

  slots = {
    acciones: ['desplazar'],
  };

  constructor(
    private formBuilder: FormBuilder,
    private swal2Service: Swal2Service,
    private TrabajaNosotrosService: TrabajaNosotrosService,
    private DetalleTrabajaNosotrosService: DetalleTrabajaNosotrosService,
    private tokenStorageService: TokenStorageService,
    //private datepipe: DatePipe
  ) {
    this.isLoggedIn = !!this.tokenStorageService.getToken();

    if (this.isLoggedIn) {
      const user = this.tokenStorageService.getUser();
      this.username = user.user.email;
    }
    this.range = new FormGroup({
      start: new FormControl(),
      end: new FormControl(),
    });
    this.iniciarForm();

  }

  ngOnInit(): void {
    let date: Date = new Date();
    /*console.log("Date = " + date);
    this.dFin=date;
    let date0: Date = new Date(date.getFullYear()+"-"+(date.getMonth()+1)+"-01");
    this.dIni=date0;*/

    var primerDia:Date = new Date(date.getFullYear(), date.getMonth(), 1);
    var ultimoDia:Date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    //console.log('ultimoDia',ultimoDia.getDate());
    //this.dIni=('0'+primerDia.getDate()).substr(0,2)+'/'+('0'+primerDia.getMonth()).substr(0,2)+'/'+primerDia.getFullYear();
    this.dIni=primerDia.getFullYear()+'-'+('0'+(primerDia.getMonth()+1)).substr(-2,2)+'-'+('0'+primerDia.getDate()).substr(-2,2);
    this.dFin=ultimoDia.getFullYear()+'-'+('0'+(ultimoDia.getMonth()+1)).substr(-2,2)+'-'+('0'+ultimoDia.getDate()).substr(-2,2);
    //this.datepipe.transform(this.date, 'yyyy-MM-dd');
   // console.log("this.dIni " + this.dIni);
   // console.log("this.dFin " + this.dFin);

    this.listTrabajaNosotros();
  }

  iniciarForm(): void {
    this.form = this.formBuilder.group({
      opcion: [''],
      dIni:[''],
      dFin:[''],
      cBus:[''],
      iConvId: [''],
      cConvTitulo: [''],
      cConvDescripcion: [''],
      cConvArchivo: [''],
      cConvLink: [''],
      cConvColorTexto: [''],
      cConvColorFondo: [''],
      dConvFechaPublicacion: [''],
      dConvFechaVigencia: [''],
      cConvOrden: [''],
      iConvVisitas: [''],
      iEstado: [''],
      bHabilitado: [''],
    });

    this.formDetalle = this.formBuilder.group({
      opcion: ['CONSULTAR'],
      iDetaConvId: [''],
      iConvId: [''],
      cDetaConvTitulo: [''],
      cDetaConvDescripcion: [''],
      cDetaConvArchivo: [''],
      cDetaConvLink: [''],
      cDetaConvColorTexto: [''],
      cDetaConvColorFondo: [''],
      // cDetaConvBases: [''],
      // cDetaConvResulEvalua: [''],
      // cDetaConvResulConoce: [''],
      // cDetaConvResulFinal: [''],
      dDetaConvFechaPublicacion: [''],
      dDetaConvFechaVigencia: [''],
      cDetaConvOrden: [''],
      iDetaConvVisitas: [''],
      iDetaConvAnio: [''],
      bDetaConvHabilitado: [''],

      iEstado: [''],
      bHabilitado: ['1'],
      //cUsuarioSis: [''],
      cUsuarioSis: [this.username]
    });
  }

  listTrabajaNosotros(): void {
    this.form.controls.opcion.setValue('CONSULTAR');
    this.form.controls.dIni.setValue(this.dIni);
    this.form.controls.dFin.setValue(this.dFin);
    this.form.controls.cBus.setValue(this.cBus);
    this.TrabajaNosotrosService.filtrar(this.form.value).subscribe(
      (resp: any) => {
        this.datos = [];
        this.datos = resp.data;
        this.datos.forEach((item:any) => {
          item.estadoPanel = false
        })
       // this.dataSource = new MatTableDataSource(this.datos);


        for (let x = 0; x < this.datos.length; x++) {
          this.datos[x].json_convocatorias=JSON.parse(
           this.datos[x].cConvArchivo
           );
          this.listDetalleTrabajas2(this.datos[x].iConvId, x);
          //this.datos[x]['json_detalle_convocatorias'] = JSON.parse(
           // this.datos[x]['json_detalle_convocatorias']
          //);
        }
      },
      (error: any) => {
        this.swal2Service.alertaToast(
          'ATENCIÓN',
          error.error.mensaje,
          'warning'
        );
      }
    );
  }

  updateTrabajaNosotros(): void {
    this.TrabajaNosotrosService.update(this.form.value).subscribe(
      (data: any) => {
        // this.swal2Service.alertaToast(
        //   'Actualizado',
        //   'Se actualizó con éxito',
        //   'success'
        // );
        this.form.reset();
        this.listTrabajaNosotros();
      },
      (error: any) => {
        // this.swal2Service.alertaToast(
        //   'ATENCIÓN',
        //   error.error.mensaje,
        //   'warning'
        // );
      }
    );
  }

  accionBtnItemConvocatorias(elemento: any): void {
    const { accion } = elemento;
    const { item } = elemento;
    switch (accion) {
      case 'visita':
        item.opcion = 'VISITA';
        this.form.patchValue(item);
        this.updateTrabajaNosotros();
        break;

      case 'desplazar':

        item.opcion = 'CONSULTARxiConvId';

        //this.formDetalle.patchValue(item);
        //json_detalle_convocatorias
        this.datosDetalle = [];
        //console.log(item.json_detalle_convocatorias)
        if (item.json_detalle_convocatorias) {
          this.datosDetalle = item.json_detalle_convocatorias;
        } else {
          this.datosDetalle = [];
        }

        //this.listDetalleTrabajas(item.iConvId);

        break;

      default:
        break;
    }
  }

  columnasdetalle = [
    {
      titulo: 'Detalle de Convocatoria',
      value: 'cDetaConvTitulo',

      width: '15%',
      text: 'justify',
      pdf: false,
    },
    {
      titulo: 'Descripción',
      value: 'cDetaConvDescripcion',

      width: '25%',
      text: 'justify',
      pdf: false,
    },
    {
      titulo: 'Visitas',
      value: 'iDetaConvVisitas',

      width: '8%',
      text: 'center',
      pdf: false,
    },

    {
      titulo: 'Publicado por:',
      value: 'name',

      width: '14%',
      text: 'justify',
      pdf: false,
    },
    {
      titulo: 'Fecha de Publicación',
      value: 'dDetaConvFechaPublicacionWeb',

      width: '14%',
      text: 'center',
      pdf: false,
    },
    {
      titulo: 'Fecha Vigencia',
      value: 'dDetaConvFechaVigenciaWeb',

      width: '14%',
      text: 'center',
      pdf: false,
    },
    {
      titulo: 'Archivo',
      value: 'cDetaConvArchivo',

      width: '10%',
      text: 'center',
      pdf: true,
    },

    // {
    //   titulo: 'Acciones',
    //   value: 'acciones',
    //   slot: true,
    //   center: true,
    //   width: '5%',
    //   text_align: 'center',
    //   pdf: false,
    // },
  ];

  listDetalleTrabajas(iConvId: any): void {
    this.formDetalle.controls.cUsuarioSis.setValue(this.username);
    this.formDetalle.controls.opcion.setValue('CONSULTARxiConvId');
    this.formDetalle.controls.iConvId.setValue(iConvId);
    this.DetalleTrabajaNosotrosService.list(this.formDetalle.value).subscribe(
      (resp: any) => {
        this.datosDetalle = [];
        this.datosDetalle = resp.data;
        /*for (let x = 0; x < this.datos.length; x++) {
          this.datos[x]['json_detalle_convocatorias'] = JSON.parse(
            this.datos[x]['json_detalle_convocatorias']
          );
        }*/
      },

      (error: any) => {
        this.swal2Service.alertaToast(
          'ATENCIÓN',
          error.error.mensaje,
          'warning'
        );
      }
    );
  }

  listDetalleTrabajas2(iConvId: any, y: any): void {
    this.formDetalle.controls.cUsuarioSis.setValue(this.username);
    this.formDetalle.controls.opcion.setValue('CONSULTARxiConvId');
    this.formDetalle.controls.iConvId.setValue(iConvId);
    this.DetalleTrabajaNosotrosService.list(this.formDetalle.value).subscribe(
      (resp: any) => {
        this.datos2 = [];
        this.datos2 = resp.data;
        this.datos[y]['json_detalle_convocatorias']=this.datos2;

        if (this.datos2.length>0){
          for (let x = 0; x < this.datos2.length; x++) {
             this.datos[y]['json_detalle_convocatorias'][x].archivos=JSON.parse(this.datos2[x].cDetaConvArchivo);
          }
        }

      },

      (error: any) => {
        //console.log(error)
        // this.swal2Service.alertaToast(
        //   'ATENCIÓN',
        //   error.error.mensaje,
        //   'warning'
        // );
      }
    );
  }

  updateDetalleTrabajas(accion: any) {
    // if (!this.visita) {
    //   this.formDetalle.controls.opcion.setValue('EDITAR');
    // }
    this.formDetalle.controls.opcion.setValue(accion);

    this.DetalleTrabajaNosotrosService.update(this.formDetalle.value).subscribe(
      (resp: any) => {
        //console.log(resp)
        // this.swal2Service.alertaToast(
        //   'Actualizado',
        //   'Se actualizó con éxito',
        //   'success'
        // );
        this.listDetalleTrabajas(this.formDetalle.value.iConvId);
        const iConvId = this.formDetalle.value.iConvId;
        this.formDetalle.reset();
        this.formDetalle.controls.iConvId.setValue(iConvId);
      },

      (error: any) => {
        //console.log(error)
        this.swal2Service.alertaToast(
          'ATENCIÓN',
          error.error.mensaje,
          'warning'
        );
      }
    );
  }

  visita?: boolean = false;
  accionBtnItemDetalleTrabajas(elemento: any, id: any) {
    const { accion } = elemento;
    const { item } = elemento;
    this.visita = false;
    switch (accion) {
      case 'visita':
        this.visita = true;
        item.opcion = 'VISITA';
        this.formDetalle.patchValue(item);
        this.updateDetalleTrabajas('VISITA');
        break;
      default:
        break;
    }
  }

  accionBtnItemDetalleTrabajas_(id: any) {
    this.visita = false;

        this.visita = true;
        this.formDetalle.controls.iDetaConvId.setValue(id);
        this.formDetalle.controls.opcion.setValue('VISITA');
        this.updateDetalleTrabajas('VISITA');
  }

  accionBtnItemTrabajas_(id: any) {
    this.visita = false;
        this.visita = true;
        this.form.controls.iConvId.setValue(id);
        this.form.controls.opcion.setValue('VISITA');
        this.updateTrabajaNosotros();
  }


  print() {
    // do other stuff...
    window.print();
  }

  togglePanel(estado:boolean) {
    estado = !estado
  }

}
