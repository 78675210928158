import { Component, OnInit, ViewChild } from '@angular/core';
import { PortadaService } from 'src/app/servicios/portada.service';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
} from 'swiper';
import { BehaviorSubject } from 'rxjs';
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
]);
import Swiper from 'swiper';
@Component({
  selector: 'app-portada',
  templateUrl: './portada.component.html',
  styleUrls: ['./portada.component.css'],
})
export class PortadaComponent implements OnInit {
  backend = environment.backendStorage;
  @ViewChild('swiperRef', { static: false }) swiperRef?: SwiperComponent;
  formPortada!: FormGroup;
  portada: any = [];
  show?: boolean;
  thumbs: any;
  slides$ = new BehaviorSubject<string[]>(['']);
  constructor(
    private formBuilder: FormBuilder,
    private PortadaService: PortadaService
  ) {}

  ngOnInit(): void {
    this.iniciarForm();
    this.listPortada();
  }
  iniciarForm(): void {
    this.formPortada = this.formBuilder.group({
      opcion: ['CONSULTAR'],
      valorBusqueda: [''],
    });
  }
  listPortada() {
    this.PortadaService.list(this.formPortada.value).subscribe(
      (resp: any) => {
        //console.log(resp);
        this.portada = [];
        this.portada = resp.data;
      },

      (error: any) => {
        //console.log(error)
        // this.swal2Service.alertaToast(
        //   'ATENCIÓN',
        //   error.error.mensaje,
        //   'warning'
        // );
      }
    );
  }
  getSlides() {
    this.slides$.next(
      Array.from({ length: 600 }).map((el, index) => `Slide ${index + 1}`)
    );
  }

  thumbsSwiper: any;
  setThumbsSwiper(swiper: any) {
    this.thumbsSwiper = swiper;
  }
  controlledSwiper: any;
  setControlledSwiper(swiper: any) {
    this.controlledSwiper = swiper;
  }
}
