import { Component, OnInit } from '@angular/core';
import { ConfiguracionService } from 'src/app/servicios/configuracion.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Swal2Service } from 'src/app/servicios/swal2.service';

declare const require: any;
const AOS = require('aos');

@Component({
  selector: 'app-root',
  template: `
    <div id="main-wrapper" *ngIf="configuracion.length">
      <loader-global></loader-global>
      <app-emergentes></app-emergentes>
      <app-header [data]="configuracion"></app-header> <!-- <div class="clearfix"></div> -->
      <router-outlet #outlet="outlet"></router-outlet>
      <app-footer [configuracion]=configuracion></app-footer>
    </div>`,

})
export class AppComponent implements OnInit {
  title = 'hacms';
  formConfiguracion!: FormGroup;
  configuracion: any = [];
  constructor(
    private formBuilder: FormBuilder,
    private ConfiguracionService: ConfiguracionService,
    private swal2Service: Swal2Service,

  ) {}
  ngOnInit() {
    //console.log(AOS)
    AOS.init();
    //this.configuracion.push({cConfHeaderColor:'white', cConfColor:'white'});
    this.iniciarForm();
    this.listConfiguracion();
  }
  iniciarForm() {
    this.formConfiguracion = this.formBuilder.group({
      opcion: ['CONSULTAR'],
      valorBusqueda: [''],
      iConfId: [''],
      cConfTitulo: ['', Validators.required],
      cConfDescripcion: ['', Validators.required],
      cConfLogo: ['', Validators.required],
      cConfColor: ['', Validators.required],
      bConfHabilitado: ['', Validators.required],
      cConfHeader: ['', Validators.required],
      cConfHeaderLogo: ['', Validators.required],
      cConfHeaderColor: ['', Validators.required],
      bConfHeaderHabilitado: ['', Validators.required],
      cConfFooter: ['', Validators.required],
      cConfFooterLogo: ['', Validators.required],
      cConfFooterColor: ['', Validators.required],
      cConfFooterCopy: ['', Validators.required],
      bConfFooterHabilitado: ['', Validators.required],
      cConfPaginaAnterior: ['', Validators.required],
      cConfCorreo: ['', Validators.required],
      cConfDireccion: ['', Validators.required],
      cConfTelefono: ['', Validators.required],
      cConfHorario: ['', Validators.required],
      cConfAnio: ['', Validators.required],
      cConfYoutube: ['', Validators.required],
      cConfYoutubeLogo: ['', Validators.required],
      cConfYoutubeLink: ['', Validators.required],
      bConfYoutubeHabilitado: ['', Validators.required],
      cConfInstagram: ['', Validators.required],
      cConfInstagramLogo: ['', Validators.required],
      cConfInstagramLink: ['', Validators.required],
      bConfInstagramHabilitado: ['', Validators.required],
      cConftwiter: ['', Validators.required],
      cConftwiterLogo: ['', Validators.required],
      cConftwiterLink: ['', Validators.required],
      bConftwiterHabilitado: ['', Validators.required],
      cConfFacebook: ['', Validators.required],
      cConfFacebookLogo: ['', Validators.required],
      cConfFacebookLink: ['', Validators.required],
      bConfFacebookHabilitado: ['', Validators.required],
      cConfWhatsapp: ['', Validators.required],
      cConfWhatsappLogo: ['', Validators.required],
      cConfWhatsappLink: ['', Validators.required],
      bConfWhatsappHabilitado: ['', Validators.required],
      iEstado: ['', Validators.required],
      bHabilitado: ['', Validators.required],
    });
  }

  listConfiguracion(): void {
    this.ConfiguracionService.list(this.formConfiguracion.value).subscribe(
      (resp: any) => {
        this.configuracion = [];
        this.configuracion = resp.data;
      },

      (error: any) => {

        this.swal2Service.alertaToast(
          'ATENCIÓN',
          error.error.mensaje,
          'warning'
        );
      }
    );
  }

}
