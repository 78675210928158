import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { InicioComponent } from './pages/inicio/inicio.component';
import { TrabajaNosotrosComponent } from 'src/app/pages/trabaja-nosotros/trabaja-nosotros.component';
import { ResolucionesComponent } from './pages/resoluciones/resoluciones.component';
import { DirectivasComponent } from './pages/directivas/directivas.component';
import { ContactenosComponent } from './pages/seccion/contactenos/contactenos.component';
import { NoticiasComponent } from './pages/seccion/noticias/noticias.component';
import { DetalleSubmenuComponent } from './pages/seccion/detalle-submenu/detalle-submenu.component';
import { DetalleNoticiasComponent } from './pages/seccion/detalle-noticias/detalle-noticias.component';
import { ArticulosComponent } from './pages/seccion/articulos/articulos.component';
import { DetalleArticulosComponent } from './pages/seccion/detalle-articulos/detalle-articulos.component';
const routes: Routes = [
  {
    path: '',
    component: InicioComponent,
  },
  {path: 'trabaja-nosotros', component: TrabajaNosotrosComponent},
  {path: 'directivas',   component: DirectivasComponent},
  {path: 'resoluciones',   component: ResolucionesComponent},
  {path: 'contactenos',   component: ContactenosComponent},
  {path: 'noticias',   component: NoticiasComponent},
  {path: 'detalle-submenu/:iSubMenuId/:cSubMenuNombre',   component: DetalleSubmenuComponent},
  {path: 'detalle-noticias/:iNotiId',   component: DetalleNoticiasComponent},
  {path: 'articulos',   component: ArticulosComponent},
  {path: 'detalle-articulos/:iArtiId',   component: DetalleArticulosComponent},
  
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
