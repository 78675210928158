<section id="contact" class="contact" *ngIf="configuracion.length">
  <div class="container" data-aos="fade-up">
    <section class="intro-single">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-8">
            <div class="title-single-box">
              <h1 class="title-single">&nbsp;&nbsp;CONTÁCTENOS</h1>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="container">
      <div class="row gy-4">
        <div class="col-lg-6">
          <div class="row gy-4">
            <div class="col-md-6">
              <div class="info-box">
                <i class="bi bi-geo-alt"></i>
                <h3>Dirección</h3>

                <p>{{ configuracion[0].cConfDireccion }}</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="info-box">
                <i class="bi bi-telephone"></i>
                <h3>Llámanos</h3>
                <p>{{ configuracion[0].cConfTelefono }}</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="info-box">
                <i class="bi bi-envelope"></i>
                <h3>Escríbenos</h3>
                <p>{{ configuracion[0].cConfCorreo }}</p>
              </div>
            </div>
            <div class="col-md-6">
              <div class="info-box">
                <i class="bi bi-clock"></i>
                <h3>Hora de Atención</h3>
                <p>{{ configuracion[0].cConfHorario }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-6">
          <form [formGroup]="form">
            <div class="row gy-4">
              <div class="col-md-6">
                <input
                  type="text"
                  name="name"
                  class="form-control"
                  formControlName="cContNombre" 
                  placeholder="Ingrese su Nombre"
                  required
                />
              </div>

              <div class="col-md-6">
                <input
                  type="email"
                  class="form-control"
                  formControlName="cContCorreo" 
                  name="email"
                  placeholder="Ingrese su Correo Electrónico"
                  required
                />
              </div>

              <div class="col-md-12">
                <input
                  type="text"
                  class="form-control"
                  formControlName="cContAsunto" 
                  name="subject"
                  placeholder="Ingrese su Asunto"
                  required
                />
              </div>

              <div class="col-md-12">
                <textarea
                  class="form-control"
                  formControlName="cContMensaje"
                  name="message"
                  rows="6"
                  placeholder="Ingrese su mensaje"
                  required
                ></textarea>
              </div>

              <button 
                type="submit"  
                class="btn btn-outline-primary btn-block"
                (click)="sendContactenos()">
                ENVIAR
              </button>
                
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="intro-single">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-lg-8">
        <div class="title-single-box">
          <h1 class="title-single">&nbsp;&nbsp;UBÍQUENOS</h1>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <div class="contact-map box">
          <div id="map" class="contact-map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3811.2385065221447!2d-70.94874668512618!3d-17.207215188186805!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x91449c78f2189caf%3A0xb6e84f040d3f9461!2sGREMO%20Moquegua!5e0!3m2!1ses!2spe!4v1639990835639!5m2!1ses!2spe"
              width="100%"
              height="450"
              style="border: 0"
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
