<!--
    <swiper
      [loop]="true"
      (swiper)="setControlledSwiper($event)"
      [pagination]="{ clickable: true }"
      [navigation]="true"
      [watchSlidesProgress]="true"
      [allowTouchMove]="true"
      [autoplay]="{ delay: 1000, disableOnInteraction: false }"
    >
      <ng-template
        data-swiper-autoplay="2000"
        swiperSlide
        *ngFor="let slide of portada; let i = index"
      >
        <div class="img-holder">
          <div>
            <img class="image" [src]="slide.cPortImagen" />
          </div>
        </div>
      </ng-template>
    </swiper> -->
<mat-carousel
  data-aos="fade-up"
  data-aos-delay="200"
  timings="250ms ease-in"
  [autoplay]="true"
  [interval]="1500"
  color="primary"
  maxWidth="auto"
  [proportion]="50"
  [slides]="portada.length"
  [loop]="true"
  [hideArrows]="false"
  [hideIndicators]="true"
  [useKeyboard]="true"
  [useMouseWheel]="false"
  orientation="ltr"
  style="object-fit: cover"
>

    <mat-carousel-slide
      #matCarouselSlide
      *ngFor="let slide of portada; let i = index"
      [image]="backend+'/'+slide.cPortImagen"
      style="object-fit: cover"
      [hideOverlay]="true"
    >
    </mat-carousel-slide>

</mat-carousel>
