import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder,FormGroup } from '@angular/forms';
import { ArticulosService } from 'src/app/servicios/articulos.service';
import { environment } from 'src/environments/environment';
import { Swal2Service } from 'src/app/servicios/swal2.service';
import { Router, ActivatedRoute } from '@angular/router';
import { InteresesService } from 'src/app/servicios/intereses.service';
import SwiperCore, { Pagination } from "swiper";

// install Swiper modules
SwiperCore.use([Pagination]);
@Component({
  selector: 'app-detalle-articulos',
  templateUrl: './detalle-articulos.component.html',
  styleUrls: ['./detalle-articulos.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DetalleArticulosComponent implements OnInit {
  iArtiId: any;
  backend = environment.backendStorage;
  form!: FormGroup;
  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private ArticulosService: ArticulosService,
    private swal2Service: Swal2Service,
    private InteresesService: InteresesService
  ) {
    this.iArtiId = null;
    
    this.activatedRoute.params.subscribe((params: any) => {
      this.iArtiId = params.iArtiId;
    });
    this.iniciarForm();
  }

  ngOnInit(): void {
    this.iniciarForm();
    this.listArticulos();
    this.listIntereses();
  }
  iniciarForm(): void {
    this.form = this.formBuilder.group({
      opcion: ['CONSULTAR'],
      iArtiId: [''],
      cArtiTitulo: [''],
      cArtiDescripcion: [''],
      cArtiImagen: [''],
      cArtiLink: [''],
      cArtiOrden: [''],
      iArtiVisitas: [''],
      iArtiAnio: [''],
      dArtiFechaCreacion: [''],
      dArtiFechaVigencia: [''],
      cUsuarioSis: [''],
    });
  }

  articulo: any = [];
  listArticulos(): void {
    this.form.controls.opcion.setValue('CONSULTARxiArtiId');
    this.form.controls.iArtiId.setValue(this.iArtiId);
    this.ArticulosService.list(this.form.value).subscribe(
      (resp: any) => {
        this.articulo = [];
        this.articulo = resp.data;
      },
      (error: any) => {
        //console.log(error)
        this.swal2Service.alertaToast(
          'ATENCIÓN',
          error.error.mensaje,
          'warning'
        );
      }
    );
  }

  interes:any=[];
  listIntereses(): void {
    this.form.controls.opcion.setValue('CONSULTAR');
    this.InteresesService.list(this.form.value).subscribe(
      (resp: any) => {
        this.interes = [];
        this.interes = resp.data;
      },
      (error: any) => {
        //console.log(error)
        // this.swal2Service.alertaToast(
        //   'ATENCIÓN',
        //   error.error.mensaje,
        //   'warning'
        // );
      }
    );
  }
  goToLink(url: any) {
    window.open("//" + url, '_blank');
  }

  controlledSwiper: any;
  setControlledSwiper(swiper: any) {
    this.controlledSwiper = swiper;
  }
}
