<section
  id="recent-blog-posts"
  class="recent-blog-posts"
  *ngIf="articulo.length > 0"
>
  <div class="container" data-aos="fade-up">
    <div class="row" *ngFor="let item of articulo" style="margin-top: -2vh">
      <div class="col-lg-12">
        <section class="intro-single">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-lg-8">
                <div class="title-single-box">
                  <h1 class="title-single">&nbsp;&nbsp;DETALLE ARTÍCULO</h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- <div class="col-lg-12">
        <div class="cources_price">Título: {{ item.cArtiTitulo }}</div>
        <br />
      </div> -->
      <div class="education_block_body">
        <h2 class="bl-title">Título: {{ item.cArtiTitulo }}</h2>
      </div>

      <div class="col-lg-4">
        <div class="post-box">
          <div class="post-img">
            <img
              src="{{ backend }}/{{ item.cArtiImagen }}"
              style="object-fit: cover"
              width="100%"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <!-- <div class="post-title">{{ item.cArtiTitulo }}</div> -->
        <br />
        <div class="row">
          <div class="col-lg-6" align="left">
            <div class="cources_info_style3 pb-2">
              <ul>
                <li class="d-flex dos">
                  <mat-icon class="mr-2">event</mat-icon> Publicado:
                  {{ item.dArtiFechaCreacion | date: "shortDate" }}
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6" align="right">
            <div class="cources_info_style3 pb-2">
              <ul>
                <li class="d-flex dos">
                  <mat-icon class="mr-2">preview</mat-icon> Visitas:
                  {{ item.iArtiVisitas }}
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-12">
            <small>Categoría: <span class="text-muted"> {{item.cCategoriaDesc}}</span></small>
          </div>
        </div>

        <div style="font-size: x-small; text-align: justify" [innerHTML]="item.cArtiDescripcion | htmlPipe">

        </div>
      </div>
    </div>
  </div>
</section>
