import {
  OnInit,
  ChangeDetectorRef,
  Component,
  NgZone,
  ViewChild,Inject
} from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ConfiguracionService } from 'src/app/servicios/configuracion.service';
import { NoticiasService } from 'src/app/servicios/noticias.service';
import { InteresesService } from 'src/app/servicios/intereses.service';
import { AplicacionesService } from 'src/app/servicios/aplicaciones.service';
import { Swal2Service } from 'src/app/servicios/swal2.service';
import { SwiperComponent } from 'swiper/angular';
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
} from 'swiper';
import { BehaviorSubject } from 'rxjs';
import Swiper from 'swiper/types/swiper-class';

//import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { MatDialog } from '@angular/material/dialog';
// install Swiper components
SwiperCore.use([
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Virtual,
  Zoom,
  Autoplay,
  Thumbs,
  Controller,
]);
@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.scss'],
  styles: [
    `
      .bg-yellow {
        background-color: yellow;
      }
      .transition {
        transition: background 0.25s ease, color 0.25s ease;
      }
      .active-slide {
        background-color: green;
        color: #fff;
      }
      .bg-blue {
        background-color: blue;
        color: #fff;
      }
    `,
  ],
})
export class InicioComponent implements OnInit {
  @ViewChild('swiperRef', { static: false }) swiperRef?: SwiperComponent;
  slides$ = new BehaviorSubject<string[]>(['']);
  show?: boolean;
  thumbs: any;
  
  backend = environment.backendStorage;
  formConfiguracion!: FormGroup;
  form!: FormGroup;
  formInteres!: FormGroup;
  configuracion: any = [];
  constructor(
    private formBuilder: FormBuilder,
    private ConfiguracionService: ConfiguracionService,
    private NoticiasService: NoticiasService,
    private swal2Service: Swal2Service,
    private cd: ChangeDetectorRef,
    private ngZone: NgZone,
    private InteresesService: InteresesService,
    public dialog: MatDialog    
  ) {}

  ngOnInit(): void {
    this.iniciarForm();
    this.configuracion.push({ cConfHeaderColor: 'white', cConfColor: 'white' });
    this.listConfiguracion();
    this.listNoticias();
    this.listIntereses();
    this.listAplicaciones();
  }
  mySwiper: any;

  iniciarForm(): void {
    this.formConfiguracion = this.formBuilder.group({
      opcion: ['CONSULTAR'],
      valorBusqueda: [''],
      iConfId: [''],
      cConfTitulo: ['', Validators.required],
      cConfDescripcion: ['', Validators.required],
      cConfLogo: ['', Validators.required],
      cConfColor: ['', Validators.required],
      bConfHabilitado: ['', Validators.required],
      cConfHeader: ['', Validators.required],
      cConfHeaderLogo: ['', Validators.required],
      cConfHeaderColor: ['', Validators.required],
      bConfHeaderHabilitado: ['', Validators.required],
      cConfFooter: ['', Validators.required],
      cConfFooterLogo: ['', Validators.required],
      cConfFooterColor: ['', Validators.required],
      cConfFooterCopy: ['', Validators.required],
      bConfFooterHabilitado: ['', Validators.required],
      cConfPaginaAnterior: ['', Validators.required],
      cConfCorreo: ['', Validators.required],
      cConfDireccion: ['', Validators.required],
      cConfTelefono: ['', Validators.required],
      cConfHorario: ['', Validators.required],
      cConfAnio: ['', Validators.required],
      cConfYoutube: ['', Validators.required],
      cConfYoutubeLogo: ['', Validators.required],
      cConfYoutubeLink: ['', Validators.required],
      bConfYoutubeHabilitado: ['', Validators.required],
      cConfInstagram: ['', Validators.required],
      cConfInstagramLogo: ['', Validators.required],
      cConfInstagramLink: ['', Validators.required],
      bConfInstagramHabilitado: ['', Validators.required],
      cConftwiter: ['', Validators.required],
      cConftwiterLogo: ['', Validators.required],
      cConftwiterLink: ['', Validators.required],
      bConftwiterHabilitado: ['', Validators.required],
      cConfFacebook: ['', Validators.required],
      cConfFacebookLogo: ['', Validators.required],
      cConfFacebookLink: ['', Validators.required],
      bConfFacebookHabilitado: ['', Validators.required],
      cConfWhatsapp: ['', Validators.required],
      cConfWhatsappLogo: ['', Validators.required],
      cConfWhatsappLink: ['', Validators.required],
      bConfWhatsappHabilitado: ['', Validators.required],
      iEstado: ['', Validators.required],
      bHabilitado: ['', Validators.required],
    });
    this.form = this.formBuilder.group({
      opcion: ['CONSULTAR'],
      iNotiId: [''],
      cNotiTitulo: [''],
      cNotiDescripcion: [''],
      cNotiImagen: [''],
      cNotiLink: [''],
      cNotiOrden: [''],
      iNotiVisitas: [''],
      iNotiAnio: [''],
      dNotiFechaCreacion: [''],
      dNotiFechaVigencia: [''],
      cUsuarioSis: [''],
    });
    this.formInteres = this.formBuilder.group({
      opcion: ['CONSULTAR'],
      iInteId: [''],
      cInteTitulo: [''],
      cInteDescripcion: [''],
      cInteImagen: [''],
      cInteLink: [''],
      cInteOrden: [''],
      cInteVisitas: [''],
      cInteAnio: [''],
      dInteFechaCreacion: [''],
      dInteFechaVigencia: [''],
      bInteHabilitado: [''],
      cUsuarioSis: [''],
    });
  }

  listConfiguracion(): void {
    this.ConfiguracionService.list(this.formConfiguracion.value).subscribe(
      (resp: any) => {
        this.configuracion = [];
        this.configuracion = resp.data;
        //console.log(this.configuracion)
      },

      (error: any) => {
        //console.log(error)
        // this.swal2Service.alertaToast(
        //   'ATENCIÓN',
        //   error.error.mensaje,
        //   'warning'
        // );
      }
    );
  }

  getSlides() {
    this.slides$.next(
      Array.from({ length: 600 }).map((el, index) => `Slide ${index + 1}`)
    );
  }
  thumbsSwiper: any;
  setThumbsSwiper(swiper: any) {
    this.thumbsSwiper = swiper;
  }
  controlledSwiper: any;
  setControlledSwiper(swiper: any) {
    this.controlledSwiper = swiper;
  }
  indexNumber = 1;
  exampleConfig = { slidesPerView: 3 };
  slidesPerView: number = 4;
  pagination: any = false;

  slides2 = ['slide 1', 'slide 2', 'slide 3'];
  replaceSlides() {
    this.slides2 = ['foo', 'bar'];
  }

  togglePagination() {
    if (!this.pagination) {
      this.pagination = { type: 'fraction' };
    } else {
      this.pagination = false;
    }
  }

  navigation = false;
  toggleNavigation() {
    this.navigation = !this.navigation;
  }

  scrollbar: any = false;
  toggleScrollbar() {
    if (!this.scrollbar) {
      this.scrollbar = { draggable: true };
    } else {
      this.scrollbar = false;
    }
  }
  breakpoints = {
    640: { slidesPerView: 2, spaceBetween: 20 },
    768: { slidesPerView: 4, spaceBetween: 40 },
    1024: { slidesPerView: 4, spaceBetween: 50 },
  };

  slides = Array.from({ length: 5 }).map((el, index) => `Slide ${index + 1}`);
  virtualSlides = Array.from({ length: 600 }).map(
    (el, index) => `Slide ${index + 1}`
  );

  log(log: string) {
    // console.log(string);
  }

  breakPointsToggle?: boolean;
  breakpointChange() {
    this.breakPointsToggle = !this.breakPointsToggle;
    this.breakpoints = {
      640: { slidesPerView: 2, spaceBetween: 20 },
      768: { slidesPerView: 4, spaceBetween: 40 },
      1024: { slidesPerView: this.breakPointsToggle ? 7 : 5, spaceBetween: 50 },
    };
  }

  slidesEx = ['first', 'second'];

  onSlideChange(swiper: any) {
    if (swiper.isEnd) {
      // all swiper events are run outside of ngzone, so use ngzone.run or detectChanges to update the view.
      this.ngZone.run(() => {
        this.slidesEx = [...this.slidesEx, `added ${this.slidesEx.length - 1}`];
      });
      console.log(this.slidesEx);
    }
  }
  noticia: any = [];
  html: any = '';
  listNoticias(): void {
    this.form.controls.opcion.setValue('CONSULTAR');
    this.NoticiasService.list(this.form.value).subscribe(
      (resp: any) => {
        this.noticia = [];
        this.noticia = resp.data;
        for (let i = 0; i < 20; i++) {
          this.html =
            this.html + '<ng-template swiperSlide>Slide' + i + '</ng-template>';
        }
      },
      (error: any) => {
        //console.log(error)
        this.swal2Service.alertaToast(
          'ATENCIÓN',
          error.error.mensaje,
          'warning'
        );
      }
    );
  }
  interes:any=[];
  listIntereses(): void {
    this.form.controls.opcion.setValue('CONSULTAR');
    this.InteresesService.list(this.form.value).subscribe(
      (resp: any) => {
        this.interes = [];
        this.interes = resp.data;
      },
      (error: any) => {
        //console.log(error)
        // this.swal2Service.alertaToast(
        //   'ATENCIÓN',
        //   error.error.mensaje,
        //   'warning'
        // );
      }
    );
  }
  aplicaciones:any=[];
  listAplicaciones(): void {
    this.form.controls.opcion.setValue('CONSULTAR');
    
    this.InteresesService.list_aplicaciones(this.form.value).subscribe(
      (resp: any) => {
        this.aplicaciones = [];
        this.aplicaciones = resp.data;
      },
      (error: any) => {
        //console.log(error)
        // this.swal2Service.alertaToast(
        //   'ATENCIÓN',
        //   error.error.mensaje,
        //   'warning'
        // );
      }
    );
  }
  goToLink(url: any) {
    window.open("//" + url, '_blank');
  }
/*
  openDialog(): void {
    const dialogRef = this.dialog.open(DialogOverviewExampleDialog, {
      width: '250px',
      data: {name: 'prueba', animal: 'prueba 22'},
    });
  }
*/
  /*
  verMensajesPopup(customer: any) {
    this.dialog.open(DetalleDialogComponent, {
        data: customer,
        width: '600px',
    });
}*/


}
