<div align="center">
  <app-portada></app-portada>
</div>
<main id="main">
  <!-- <section id="about" class="about">
    <div class="container" data-aos="fade-up">
      <div class="row gx-0">
        <div
          class="col-lg-6 d-flex flex-column justify-content-center"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <div class="content">
            <h3>DIRECCIÓN REGIONAL DE EDUCACIÓN MOQUEGUA</h3>

            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry. Lorem Ipsum has been the industry's standard dummy text
              ever since the 1500s, when an unknown printer took a galley of
              type and scrambled it to make a type specimen book. It has
              survived not only five centuries, but also the leap into
              electronic typesetting, remaining essentially unchanged. It was
              popularised in the 1960s with the release of Letraset sheets
              containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions of
              Lorem Ipsum.
            </p>
          </div>
        </div>

        <div class="col-lg-6 d-flex align-items-center" data-aos-delay="200">
          <img src="assets/assets/img/about.jpg" class="img-fluid" alt="" />
        </div>
      </div>
    </div>
  </section> -->

  <section id="recent-blog-posts" class="recent-blog-posts">
    <div class="container" data-aos="fade-up">
      <section class="intro-single">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-8">
              <div class="title-single-box">
                <h1 class="title-single">&nbsp;&nbsp;NOTICIAS</h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div class="row">
        <swiper        
         
          [pagination]="{
            clickable: true
          }"
                
          [slidesPerView]="3"
          [spaceBetween]="50"
          (swiper)="setControlledSwiper($event)"
          [autoplay]="{ delay: 2000, disableOnInteraction: false }"
          [watchSlidesProgress]="true"
          [navigation]="true"
        class="mySwiper"
        >
          <ng-template swiperSlide *ngFor="let item of noticia">
            <div class="post-box">
              <div class="post-img">
                <img
                  src="{{ backend }}/{{ item.cNotiImagen }}"
                  alt=""
                  style="object-fit: cover"
                  width="100%"
                  height="200vh"
                />
              </div>
              <div class="post-title">{{ item.cNotiTitulo }}</div>
              <span class="post-date" style="font-size: small">{{
                item.dNotiFechaCreacion
              }}</span>
              <div style="font-size: small">
                {{ item.cNotiDescripcion | slice: 0:180 }} ...
                <br />
              </div>
              <a routerLink="/detalle-noticias/{{item.iNotiId}}" class="readmore stretched-link mt-auto"
                ><span class="cambiar-color">Leer más</span
                ><i class="bi bi-arrow-right"></i
              ></a>
            </div>
          </ng-template>
        </swiper>
        <!-- <div class="col-lg-4" *ngFor="let item of noticia">
        
        </div> -->
      </div>
    </div>
  </section>
  <section id="clients" class="clients">
    <div class="container" data-aos="fade-up">
      <section class="intro-single">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-8">
              <div class="title-single-box">
                <h1 class="title-single">&nbsp;&nbsp;ENLACES DE INTERÉS</h1>
                <br />
              </div>
            </div>
            <swiper            
              [slidesPerView]="3"
              [spaceBetween]="80"
              (swiper)="setControlledSwiper($event)"
              [autoplay]="{ delay: 3500, disableOnInteraction: false }"
              [watchSlidesProgress]="true"
            >
              
              <ng-template swiperSlide *ngFor="let item of interes">
                <a [routerLink]="" (click)="goToLink(item.cInteLink)">
                  <img
                    src="{{ backend }}/{{ item.cInteImagen }}"
                    style="object-fit: cover"
                    height="80vh"
                    width="100%"
                    alt=""
                  />
                </a>
              </ng-template>

              <!--
              <ng-template swiperSlide *ngFor="let item of interes">
                <a [routerLink]="" (click)="goToLink(item.cInteLink)">
                  <img
                    src="{{ backend }}/{{ item.cInteImagen }}"
                    style="object-fit: cover"
                    height="80vh"
                    width="auto"
                    alt=""
                  />
                </a>
              </ng-template>
              
              <ng-template swiperSlide *ngFor="let item of interes">
                <img
                  src="{{ backend }}/{{ item.cInteImagen }}"
                  style="object-fit: cover"
                  height="80vh"
                  width="auto"
                  alt=""
                />
              </ng-template> -->
            </swiper>
          </div>
          
        </div>
      </section>
      <!-- <app-enlaces-interes></app-enlaces-interes> -->
    </div>
  </section>


  <section id="clients" class="clients">
    <div class="container" data-aos="fade-up">
      <section class="intro-single">
        <div class="container">
      
          <div class="row">
            <div class="col-md-12 col-lg-8">
              <div class="title-single-box">
                <h1 class="title-single">&nbsp;&nbsp;NUESTRAS APLICACIONES</h1>
                <br />
              </div>
            </div>
            <swiper            
              [slidesPerView]="3"
              [spaceBetween]="80"
              (swiper)="setControlledSwiper($event)"
              [autoplay]="{ delay: 3500, disableOnInteraction: false }"
              [watchSlidesProgress]="true"
            >
      
              
              <ng-template swiperSlide *ngFor="let item of aplicaciones" style="text-align:center !important">
              <div style="text-align:center">
                <a [routerLink]="" (click)="goToLink(item.cApliLink)" style="text-align:center">
                  <img
                    src="{{ backend }}/{{ item.cApliImagen }}"
                    style="object-fit: cover"
                    
                
                    alt="{{ item.cApliTitulo }}"
                  />
                </a>
              </div>
                <div class="info-box">                  
                  <h6 style="text-align: center;">{{ item.cApliTitulo }}</h6>                  
                </div>
             
              </ng-template>

            </swiper>
          </div>


        </div>
      </section>
      <!-- <app-enlaces-interes></app-enlaces-interes> -->
    </div>
  </section>


  <app-contactenos></app-contactenos>
  
</main>
