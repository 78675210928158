import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TableMaterialComponent } from './table-material/table-material.component';
import { TablaExpandibleMaterialComponent } from './tabla-expandible-material/tabla-expandible-material.component';
import { ContainerPageComponent } from './container-page/container-page.component';
import { MaterialModule } from './material.module';


const components = [
  TableMaterialComponent,
  ContainerPageComponent,
  TablaExpandibleMaterialComponent
];
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    MaterialModule
  ],
  exports:components,
  declarations:components

})
export class SharedModule { }
