import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LocalService } from "./local.services";
import { AuthService } from "../shared/services/auth.service";
import { environment } from "./../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class QueryService {
  private backendApi: string;
  private backend: string;

  headers: any;
  token: any;
  ls = window.localStorage;
  httpOptions: any;

  constructor(
    private http: HttpClient,
    private local: LocalService // private auth:   ,
  ) {
    this.backendApi = environment.backendApi;
    this.backend = environment.backend;
  }

  insertHead() {
    this.ls.getItem("cmsToken");
    const data = this.local.getItem("cmsToken");
    this.token = data["access_token"];
    if (data["access_token"]) {
      this.httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        }),
      };
    }
  }
  insertHeadFiles() {
    this.ls.getItem("cmsToken");
    const data = this.local.getItem("cmsToken");
    this.token = data["access_token"];
    if (data["access_token"]) {
      this.httpOptions = {
        headers: new HttpHeaders({
          Authorization: "Bearer " + this.token,
        }),
      };
    }
  }

  subirArchivoConvocatoriasPdf(data:any) {
    return this.http.post(`${this.backendApi}/cms/general/subir-archivo-convocatorias-pdf`,
      data
    );
  }

  subirArchivoResolucionesPdf(data:any) {
    return this.http.post(`${this.backendApi}/cms/general/subir-archivo-resoluciones-pdf`,
      data
    );
  }

  subirArchivoDIrectivasPdf(data:any) {
    return this.http.post(`${this.backendApi}/cms/general/subir-archivo-directivas-pdf`,
      data
    );
  }

 
}
