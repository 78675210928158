import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class TrabajaNosotrosService {
  private backendApi: string;
  ls = window.localStorage;
  token: any;
  httpOptions: any;
  constructor(
    private http: HttpClient,

  ) {
    
    this.backendApi = environment.backendApi;

  }

  list(data: any) {
    //console.log(data)
    return this.http.post(
      `${this.backendApi}/cms/trabaja-nosotros/list`,
      data
    );
  }
  store(data: any) {
    //console.log(data)
    return this.http.post(
      `${this.backendApi}/cms/trabaja-nosotros/store`,
      data
    );
  }

  update(data: any) {
    return this.http.post(
      `${this.backendApi}/cms/trabaja-nosotros/update`,
      data
    );
  }
  filtrar(data: any) {
    //console.log(data)
    return this.http.post(
      `${this.backendApi}/cms/trabaja-nosotros/filtrar`,
      data
    );
  }

}
