import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Swal2Service } from 'src/app/servicios/swal2.service';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ContenidoService } from 'src/app/servicios/contenido.service';
import {formattedError} from "@angular/compiler";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-detalle-submenu',
  templateUrl: './detalle-submenu.component.html',
  styleUrls: ['./detalle-submenu.component.css'],
})
export class DetalleSubmenuComponent implements OnInit {
  iSubMenuId:any;
  cSubMenuNombre:any;
  data:any;
  formContenido!: FormGroup;
  contenido:any;
  backend: string = environment.backendStorage
  constructor(
    private activatedRoute: ActivatedRoute,
    private swal2Service: Swal2Service,
    private router: Router,
    private formBuilder: FormBuilder,
    private ContenidoService: ContenidoService
  ) {
    this.iSubMenuId = null;
    this.contenido = [];
    this.activatedRoute.params.subscribe((params: any) => {
      this.iSubMenuId = params.iSubMenuId;
      this.cSubMenuNombre = params.cSubMenuNombre;
    });
    this.iniciarForm();
    this.showContenido();
  }

  ngOnInit(): void {

  }
  iniciarForm(): void {
    this.formContenido = this.formBuilder.group({
      opcion: [''],
      valorBusqueda: [''],
      iContId: [''],
      iSubMenutId: [this.iSubMenuId],
      cContenido: [''],
      cContColorImagen: [''],
      cContColorTexto: [''],
      iEstado: [''],
      bHabilitado: [''],
    });
  }

  showContenido() {
    console.log(this.formContenido.value)
    this.formContenido.controls.opcion.setValue('CONSULTARxiSubMenuId');
    this.ContenidoService.show(this.formContenido.value).subscribe(
      (resp: any) => {
        this.contenido = [];
        this.contenido = resp.data;
        //console.log(this.cotenido)
      },

      (error: any) => {

      }
    );
  }

  private getArticulos() {

  }
}
