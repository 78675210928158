import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { ArticulosService } from 'src/app/servicios/articulos.service';
import { environment } from 'src/environments/environment';
import { Swal2Service } from 'src/app/servicios/swal2.service';
import {ApiService} from "../../../shared/services/api.service";

@Component({
  selector: 'app-articulos',
  templateUrl: './articulos.component.html',
  styleUrls: ['./articulos.component.scss'],
})
export class ArticulosComponent implements OnInit {
  range;
  form!: FormGroup;
  formSearch!: FormGroup;
  backend = environment.backendStorage;
  categorias: any[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private ArticulosService: ArticulosService,
    private swal2Service: Swal2Service,
    private categoriasService: ApiService
  ) {
    this.range = new FormGroup({
      start: new FormControl(),
      end: new FormControl(),
    });
  }

  ngOnInit(): void {
    this.iniciarForm();
    this.listArticulos();
    this.getCategorias()
  }
  iniciarForm(): void {

      this.formSearch = this.formBuilder.group({
        opcion: ['CONSULTAR-BUSQUEDA'],
        iArtiId: [''],
        cArtiTitulo: [''],
        cArtiDescripcion: [''],
        cArtiImagen: [''],
        cArtiLink: [''],
        cArtiOrden: [''],
        iArtiVisitas: [''],
        iArtiAnio: [''],
        dArtiFechaCreacion: [''],
        dArtiFechaVigencia: [''],
        cUsuarioSis:[''],
        iCategoriaId: [0]
      });

    this.form = this.formBuilder.group({
      opcion: ['CONSULTAR'],
      iArtiId: [''],
      cArtiTitulo: [''],
      cArtiDescripcion: [''],
      cArtiImagen: [''],
      cArtiLink: [''],
      cArtiOrden: [''],
      iArtiVisitas: [''],
      iArtiAnio: [''],
      dArtiFechaCreacion: [''],
      dArtiFechaVigencia: [''],
      cUsuarioSis:['']
    });
  }

  articulo: any = [];
  listArticulos(): void {
    this.form.controls.opcion.setValue('CONSULTAR');
    this.ArticulosService.list(this.form.value).subscribe(
      (resp: any) => {
        this.articulo = [];
        this.articulo = resp.data;
      },
      (error: any) => {
        //console.log(error)
        this.swal2Service.alertaToast(
          'ATENCIÓN',
          error.error.mensaje,
          'warning'
        );
      }
    );
  }

  SearchlistArticulos(): void {
    this.formSearch.controls.opcion.setValue('CONSULTAR-BUSQUEDA');
    //console.log(this.range.value)
    this.formSearch.controls.dArtiFechaCreacion.setValue(this.range.value.start);
    this.formSearch.controls.dArtiFechaVigencia.setValue(this.range.value.end);
    //console.log(this.formSearch)
    this.ArticulosService.list(this.formSearch.value).subscribe(
      (resp: any) => {
        this.articulo = [];
        this.articulo = resp.data;
      },
      (error: any) => {
        //console.log(error)
        this.swal2Service.alertaToast(
          'ATENCIÓN',
          error.error.mensaje,
          'warning'
        );
      }
    );
  }


  private getCategorias() {
    this.categoriasService.getCategorias()
      .subscribe((response:any) => {
        this.categorias = response['data']
      })
  }
}
