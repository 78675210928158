import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from "@angular/material/dialog";
import {EmergentesService} from "../../servicios/emergentes.service";
import {OwlOptions} from "ngx-owl-carousel-o";
import {environment} from "../../../environments/environment";


@Component({
  selector: 'app-emergentes-modal',
  templateUrl: 'emergentes.component.html',
  styleUrls: ['./emergentes.component.css']
})
export class EmergentesModalComponent implements OnInit {
  backend = environment.backendStorage
  customOptions: OwlOptions = {
    loop: true,
    autoplay: false,
    center: true,
    dots: false,
    autoHeight: true,
    autoWidth: true,
    margin: 20,
    // navText: ['Anterior', 'Siguiente'],
    nav: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    }
  }

  emergentes: any[] = []
  constructor( public dialogRef: MatDialogRef<EmergentesModalComponent>,
               @Inject(MAT_DIALOG_DATA) public data: any,) { }

  ngOnInit(): void {
    this.emergentes = this.data.emergentes
    console.log(this.data)
  }

  close() {
    this.dialogRef.close()
  }

}


@Component({
  selector: 'app-emergentes',
  template: '',
})
export class EmergentesComponent implements OnInit {

  constructor(private emergentesService: EmergentesService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.getEmergentes()
  }

  private getEmergentes() {
    this.emergentesService.getEmergentes()
      .subscribe((response:any) => {
        if (response['data'].length > 0) {
          this.opeModalEmergentes(response['data'])
        }
      })
  }

  private opeModalEmergentes(emergentes: any) {
    const data = {
      emergentes: emergentes
    }
    const ref = this.dialog.open(EmergentesModalComponent, {
      width: '90%',
      position: {top: '20px'},
      data
    })

  }
}
