<div mat-dialog-title class="d-flex justify-content-end">
  <button mat-icon-button color="warn" (click)="close()">
    <mat-icon>close</mat-icon>
  </button>
</div>

<div mat-dialog-content >
    <owl-carousel-o [options]="customOptions" #owlCar>

      <ng-container *ngFor="let slide of emergentes">
        <ng-template class="slide" carouselSlide [id]="slide.iEmerId">
          <h4 class="mb-2">{{slide.cEmerEmcabezado}}</h4>
          <div class="mb-2" *ngIf="!!slide.cEmerParrafo" [innerHTML]="slide.cEmerParrafo"></div>
          <button class="mb-2" *ngIf="!!slide.cEmerLink" mat-flat-button  color="primary" aria-label="Enlace a otra página">
            Abrir enlace  <mat-icon>open_in_new</mat-icon>
          </button>
          <img *ngIf="!!slide.cEmerImagen"  src="{{ backend }}/{{slide.cEmerImagen }}" alt=""  width="90%" height="80%">
        </ng-template>
      </ng-container>

    </owl-carousel-o>
    <button class="btn-prev" color="primary" mat-icon-button aria-label="Anterior" (click)="owlCar.prev()">
    <mat-icon>arrow_back_ios</mat-icon>
  </button>
  <button class="btn-next" color="primary" mat-icon-button aria-label="Siguiente" (click)="owlCar.next()">
    <mat-icon>arrow_forward_ios</mat-icon>
  </button>

</div>
