import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { InicioComponent } from './pages/inicio/inicio.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { LoaderService } from './components/loader/loader.service';
import { LoaderComponent } from './components/loader/loader/loader.component';
import { LoaderInterceptor } from './components/loader/loader.interceptor';

import { MatCarouselModule } from '@ngmodule/material-carousel';
import { TrabajaNosotrosComponent } from 'src/app/pages/trabaja-nosotros/trabaja-nosotros.component';
import { SharedModule } from './shared/shared.module';
import { MatPaginatorIntlEs } from './shared/custom/matPaginatorIntlEs';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatPaginatorModule } from '@angular/material/paginator';
import { ResolucionesComponent } from './pages/resoluciones/resoluciones.component';
import { DirectivasComponent } from './pages/directivas/directivas.component';

import { MisionVisionComponent } from './pages/seccion/mision-vision/mision-vision.component';
import { EnlacesInteresComponent } from './pages/seccion/enlaces-interes/enlaces-interes.component';
import { NoticiasComponent } from './pages/seccion/noticias/noticias.component';
import { PortadaComponent } from './pages/seccion/portada/portada.component';
import { ContactenosComponent } from './pages/seccion/contactenos/contactenos.component';
import { DetalleSubmenuComponent } from './pages/seccion/detalle-submenu/detalle-submenu.component';
import { DetalleNoticiasComponent } from './pages/seccion/detalle-noticias/detalle-noticias.component';

import { ArticulosComponent } from './pages/seccion/articulos/articulos.component';
import { DetalleArticulosComponent } from './pages/seccion/detalle-articulos/detalle-articulos.component';


import { SwiperModule } from 'swiper/angular';
import { NgxSummernoteModule } from 'ngx-summernote';

import {FlexLayoutModule} from '@angular/flex-layout';
import { HtmlPipePipe } from './pipes/html-pipe.pipe';
import {EmergentesComponent, EmergentesModalComponent} from "./components/emergentes/emergentes.component";
import {CarouselModule} from "ngx-owl-carousel-o";
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [
    AppComponent,
    InicioComponent,
    HeaderComponent,
    FooterComponent,
    LoaderComponent,
    TrabajaNosotrosComponent,
    ResolucionesComponent,
    DirectivasComponent,
    MisionVisionComponent,
    EnlacesInteresComponent,
    NoticiasComponent,
    PortadaComponent,
    ContactenosComponent,
    DetalleSubmenuComponent,
    DetalleNoticiasComponent,
    ArticulosComponent,
    DetalleArticulosComponent,
    HtmlPipePipe,
    EmergentesComponent,
    EmergentesModalComponent
  ],
  imports: [
    BrowserModule,
    FlexLayoutModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    MatCarouselModule.forRoot(),
    SharedModule,
    MatPaginatorModule,
    SwiperModule,
    NgxSummernoteModule,
    CarouselModule,
    NgxPaginationModule
  ],
  providers: [
    LoaderService,
    { provide: MatPaginatorIntl, useClass: MatPaginatorIntlEs },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
