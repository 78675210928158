<section id="contact" class="contact">
  <div class="container" data-aos="fade-up">
    <section class="intro-single">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-8">
            <div class="title-single-box">
              <h1 class="title-single">&nbsp;&nbsp;{{cSubMenuNombre}}</h1>
            </div>
          </div>
          <div class="col-md-12 col-lg-12" *ngFor="let item of contenido">
            <ng-container *ngIf="!!item.bConArticulos">
              <ng-container *ngTemplateOutlet="articulosList;context:{articulos: item.articulos}"
                           ngcont
              ></ng-container>
            </ng-container>
            <ng-container *ngIf="!item.bConArticulos">
              <div [ngxSummernoteView]="item.cContenido"></div>
            </ng-container>
          </div>
        </div>

      </div>
    </section>
  </div>
</section>


<ng-template #articulosList let-articulos="articulos">
  <div class="row">
    <div class="col-lg-4" *ngFor="let item of articulos">
      <div class="post-box">
        <div class="post-img">
          <img
            src="{{ backend }}/{{ item.cArtiImagen }}"
            style="object-fit: cover"
            width="100%"
            height="200vh"
          />
        </div>

        <div class="post-title">{{ item.cArtiTitulo }}</div>
        <div class="row">
          <div class="col-lg-6" align="left">
            <div class="cources_info_style3 pb-2">
              <ul>
                <li class="d-flex dos">
                  <mat-icon class="mr-2">event</mat-icon> Publicado:
                  {{ item.dArtiFechaCreacion | date: "shortDate" }}
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-6" align="right">
            <div class="cources_info_style3 pb-2">
              <ul>
                <li class="d-flex dos">
                  <mat-icon class="mr-2">preview</mat-icon> Visitas:
                  {{ item.iArtiVisitas }}
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-12">
            <small>Categoría: <span class="text-muted"> {{item.cCategoriaDesc}}</span></small>
          </div>
        </div>

        <!-- <span class="post-date" style="font-size: small;">{{
          item.dArtiFechaCreacion
        }}</span> -->
        <div style="font-size: x-small; text-align: justify" [innerHTML]="item.cArtiDescripcion | slice: 0:180 | htmlPipe">
          {{ item.cArtiDescripcion | slice: 0:180 }} ...
        </div>
        <a routerLink="/detalle-articulos/{{item.iArtiId}}" class="readmore stretched-link mt-auto"
        ><span>Leer más</span><i class="bi bi-arrow-right"></i
        ></a>
      </div>
    </div>
  </div>
</ng-template>
