import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { retry } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MenuService {
  private backendApi: string;
  ls = window.localStorage;
  token: any;
  httpOptions: any;
  constructor(
    private http: HttpClient,

  ) {
    
    this.backendApi = environment.backendApi;

  }

  list(data: any) {
    //console.log(data)
    return this.http.post(
      `${this.backendApi}/cms/menu/list`,
      data
    );
  }
  submenulist(data: any) {
    //console.log(data)
    return this.http.post(
      `${this.backendApi}/cms/menu/list_submenu`,
      data
    );
  }
}
