import { Pipe, PipeTransform } from '@angular/core';

import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
  name: 'htmlPipe'
})
export class HtmlPipePipe implements PipeTransform {

  
 constructor (private sanitizer: DomSanitizer) {
}
transform(style:any) {
return this.sanitizer.bypassSecurityTrustHtml(style);
}
/*
  transform(value: unknown, ...args: unknown[]): unknown {
    return null;
    }
  */

}
