import { MatPaginatorIntl } from '@angular/material/paginator';
import { Injectable } from '@angular/core';

@Injectable()

export class MatPaginatorIntlEs extends MatPaginatorIntl {
	itemsPerPageLabel = 'N° de registros por página';
	nextPageLabel = 'Siguiente';
	previousPageLabel = 'Anterior';
	lastPageLabel = 'Ultima Página';
	firstPageLabel = 'Primera Página';

	getRangeLabel = function (page:any, pageSize:any, length:any) {
		if (length === 0 || pageSize === 0) {
			return '0 de ' + length;
		}

		length = Math.max(length, 0);
		const startIndex = page * pageSize;
		const endIndex =
			startIndex < length
				? Math.min(startIndex + pageSize, length)
				: startIndex + pageSize;

		return startIndex + 1 + ' - ' + endIndex + ' de ' + length;
	};
}
