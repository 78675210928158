import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderService } from '../loader.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'loader-global',
  template: `<!-- <div class="all-loader" [hidden]="!loading"> <div class="spinner spinner-bubble spinner-bubble-primary mr-3"></div></div> --><!-- <div id="preloader" [ngClass]="{'d-none': !loading, 'd-block': loading}"> <div class="preloader"><span></span><span></span></div></div> --><div *ngIf="loading" id="preloader" class="d-block"> <div id="preloader"></div></div>`,
  styles: [`::ng-deep .all-loader{position:fixed;background:rgba(255,255,255,0.6);top:0;left:0;width:100%;height:100vh;z-index:99999;.loader,.spinner{position:fixed;top:45%;left:calc(50% + 56px);z-index:inherit;}}.preloader{background-color:rgba(255,255,255,0.59) !important;}`]
})
export class LoaderComponent implements OnInit {

  loading!: boolean;

  constructor(
    private loaderService: LoaderService
  ) {
    this.loaderService.isLoading.subscribe((v) => {
      // this.loading = v;
      Promise.resolve(null).then(() => this.loading = v);
    });
  }

  ngOnInit(): void {
    console.log('loader', this.loading);
  }

}
