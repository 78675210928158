// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  //backend: 'http://127.0.0.1:8000',
  //backendApi: 'http://127.0.0.1:8000/api',
  //backendStorage: "//127.0.0.1:8000/documentos",
 
  /*
  */
  /*
  backend: 'https://hacms01.solucionesfact.com/backend-hacms/public',
  backendApi: 'https://hacms01.solucionesfact.com/backend-hacms/public/api',
  backendStorage: "https://hacms01.solucionesfact.com/backend-hacms/public/documentos",
*/

   backend: 'https://pagina.luratasoft.com/backend-hacms/public',
   backendApi: 'https://pagina.luratasoft.com/backend-hacms/public/api',
   backendStorage: "https://pagina.luratasoft.com/backend-hacms/public/documentos",
  //

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
