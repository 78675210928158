import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-container-page',
  template: ` <mat-card> <mat-card-title> <div class="d-flex justify-content-between align-items-center"> <h5 class="m-0 text-uppercase text-primary">{{ titulo }}</h5> <div class="d-flex align-items-center"> <ng-content select="[slot=btnAcciones]"></ng-content> </div> </div> </mat-card-title>`,
  
})
export class ContainerPageComponent implements OnInit {
  @Output() recargarFuncionesInicio = new EventEmitter<any>();

  @Input() titulo: any = 'Titulo';
  @Input() subtitulo: any = '';
  @Input() recargar: Boolean = false;
  constructor() { }

  ngOnInit() {
  }

  recargarPagina() {
    this.recargarFuncionesInicio.emit();
  }
}
