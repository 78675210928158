import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { NoticiasService } from 'src/app/servicios/noticias.service';
import { environment } from 'src/environments/environment';
import { Swal2Service } from 'src/app/servicios/swal2.service';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.scss'],
})
export class NoticiasComponent implements OnInit {
  page: number = 1
  range;
  form!: FormGroup;
  formSearch!: FormGroup;
  backend = environment.backendStorage;
  constructor(
    private formBuilder: FormBuilder,
    private NoticiasService: NoticiasService,
    private swal2Service: Swal2Service,
  ) {
    this.range = new FormGroup({
      start: new FormControl(),
      end: new FormControl(),
    });
  }

  ngOnInit(): void {
    this.iniciarForm();
    this.listNoticias();
  }
  iniciarForm(): void {

    this.formSearch = this.formBuilder.group({
      opcion: ['CONSULTAR-BUSQUEDA'],
      iNotiId: [''],
      cNotiTitulo: [''],
      cNotiDescripcion: [''],
      cNotiImagen: [''],
      cNotiLink: [''],
      cNotiOrden: [''],
      iNotiVisitas: [''],
      iNotiAnio: [''],
      dNotiFechaCreacion: [''],
      dNotiFechaVigencia: [''],
      cUsuarioSis:['']
    });

    this.form = this.formBuilder.group({
      opcion: ['CONSULTAR'],
      iNotiId: [''],
      cNotiTitulo: [''],
      cNotiDescripcion: [''],
      cNotiImagen: [''],
      cNotiLink: [''],
      cNotiOrden: [''],
      iNotiVisitas: [''],
      iNotiAnio: [''],
      dNotiFechaCreacion: [''],
      dNotiFechaVigencia: [''],
      cUsuarioSis:['']
    });
  }

  noticia: any = [];
  listNoticias(): void {
    this.form.controls.opcion.setValue('CONSULTAR');
    this.NoticiasService.list(this.form.value).subscribe(
      (resp: any) => {
        this.noticia = [];
        this.noticia = resp.data;
      },
      (error: any) => {
        //console.log(error)
        this.swal2Service.alertaToast(
          'ATENCIÓN',
          error.error.mensaje,
          'warning'
        );
      }
    );
  }

  SearchlistNoticias(): void {
    this.formSearch.controls.opcion.setValue('CONSULTAR-BUSQUEDA');
    //console.log(this.range.value)
    this.formSearch.controls.dNotiFechaCreacion.setValue(this.range.value.start);
    this.formSearch.controls.dNotiFechaVigencia.setValue(this.range.value.end);
    //console.log(this.formSearch)
    this.NoticiasService.list(this.formSearch.value).subscribe(
      (resp: any) => {
        this.noticia = [];
        this.noticia = resp.data;
      },
      (error: any) => {
        //console.log(error)
        this.swal2Service.alertaToast(
          'ATENCIÓN',
          error.error.mensaje,
          'warning'
        );
      }
    );
  }

  pageChange(event:any) {
    this.page = event
  }

}
