<section id="recent-blog-posts" class="recent-blog-posts">
  <div class="container" data-aos="fade-up">
    <section class="intro-single">
      <div class="container">
        <div class="row">
          <div class="col-md-12 col-lg-8">
            <div class="title-single-box">
              <h1 class="title-single">&nbsp;&nbsp;ARTICULOS</h1>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="row" style="margin-top: -5vh">
      <div class="col-lg-3">
        <div class="p-2">
          <app-container-page
            class="mat-elevation-z2"
            titulo="BÚSQUEDA"
            [recargar]="false"
          >
            <ng-container slot="btnAcciones">
              <button
                class="mx-3"
                matTooltip="Buscar"
                mat-mini-fab
                color="primary"
                (click)="SearchlistArticulos();"
              >
                <mat-icon>search</mat-icon>
              </button>
            </ng-container>
          </app-container-page>

          <form [formGroup]="formSearch" style="font-size: small; padding-top: 2vh">
            <mat-form-field appearance="fill">
              <mat-label>Nombre del Artículo</mat-label>
              <textarea formControlName="cArtiTitulo" matInput row="5"></textarea>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Descripción del Artículoa</mat-label>
              <textarea formControlName="cArtiDescripcion" matInput row="5"></textarea>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Seleccion Rango de Fecha</mat-label>
              <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input
                  matStartDate
                  formControlName="start"
                  placeholder="Fecha Publicación"
                />
                <input
                  matEndDate
                  formControlName="end"
                  placeholder="Fecha Vigencia"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker>
                <mat-date-range-picker-actions>
                  <button mat-button matDateRangePickerCancel>Cancelar</button>
                  <button
                    mat-raised-button
                    color="primary"
                    matDateRangePickerApply
                  >
                    Aplicar
                  </button>
                </mat-date-range-picker-actions>
              </mat-date-range-picker>
            </mat-form-field>
            <mat-form-field appearance="fill">
              <mat-label>Categoria</mat-label>
              <mat-select formControlName="iCategoriaId">
                <mat-option [value]="0">Todas las categorias</mat-option>
                <mat-option *ngFor="let item of categorias" [value]="item.iCategoriaId">{{item.cCategoriaDesc}}</mat-option>
              </mat-select>
            </mat-form-field>
          </form>
        </div>
      </div>
      <div class="col-lg-9">
        Encontramos <strong>{{ articulo.length }}</strong> artículos para tí.
        <div class="row">
          <div class="col-lg-4" *ngFor="let item of articulo">
            <div class="post-box">
              <div class="post-img">
                <img
                  src="{{ backend }}/{{ item.cArtiImagen }}"
                  style="object-fit: cover"
                  width="100%"
                  height="200vh"
                />
              </div>

              <div class="post-title">{{ item.cArtiTitulo }}</div>
              <div class="row">
                  <div class="col-lg-6" align="left">
                    <div class="cources_info_style3 pb-2">
                      <ul>
                        <li class="d-flex dos">
                          <mat-icon class="mr-2">event</mat-icon> Publicado:
                          {{ item.dArtiFechaCreacion | date: "shortDate" }}
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-6" align="right">
                    <div class="cources_info_style3 pb-2">
                      <ul>
                        <li class="d-flex dos">
                          <mat-icon class="mr-2">preview</mat-icon> Visitas:
                          {{ item.iArtiVisitas }}
                        </li>
                      </ul>
                    </div>
                  </div>
                <div class="col-lg-12">
                 <small>Categoría: <span class="text-muted"> {{item.cCategoriaDesc}}</span></small>
                </div>
              </div>

              <!-- <span class="post-date" style="font-size: small;">{{
                item.dArtiFechaCreacion
              }}</span> -->
              <div style="font-size: x-small; text-align: justify" [innerHTML]="item.cArtiDescripcion | slice: 0:180 | htmlPipe">
                {{ item.cArtiDescripcion | slice: 0:180 }} ...
              </div>
              <a routerLink="/detalle-articulos/{{item.iArtiId}}" class="readmore stretched-link mt-auto"
                ><span>Leer más</span><i class="bi bi-arrow-right"></i
              ></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- <div class="container">
  <div class="row align-items-center" data-aos="fade-up">
    <div class="col-lg-12 col-md-12 col-sm-12">
      <section class="intro-single">
        <div class="title-single-box">
          <h1 class="title-single">&nbsp;&nbsp;Noticias</h1>
        </div>
      </section>
    </div>
    <div class="col-lg-3"></div>
    <div class="col-lg-9">
      Encontramos <strong>10</strong> noticias para tí.

      <div class="about-short">
        <div
          class="col-lg-12 col-md-12 col-sm-12 order-1 order-lg-2 order-md-1"
        >




          <div class="row arrow_slide three_slide arrow_middle">
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="singles_items">
                <div class="education_block_grid style_2">
                  <div class="education_block_thumb n-shadow banner">
                    <a>
                      <img
                        src="assets/img/edu_2.png"
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                    <div class="cources_price">NOMBRE DE NOTICIA</div>
                    <div class="education_ratting d-flex align-items-center">
                      <mat-icon class="mr-2" color="accent">room</mat-icon>
                      DETALLE DE LA NOTICIA
                    </div>
                  </div>

                  <div class="education_block_body">
                    <h4 class="bl-title">
                         </h4>
                  </div>

                  <div class="cources_info_style3 pb-2">
                    <ul>
                      <li class="d-flex align-items-center dos">
                        <mat-icon class="mr-2">event</mat-icon> Publicado:
                        10/02/2021
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="singles_items">
                <div class="education_block_grid style_2">
                  <div class="education_block_thumb n-shadow banner">
                    <a>
                      <img
                        src="assets/img/edu_2.png"
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                    <div class="cources_price">NOMBRE DE NOTICIA</div>
                    <div class="education_ratting d-flex align-items-center">
                      <mat-icon class="mr-2" color="accent">room</mat-icon>
                      DETALLE DE LA NOTICIA
                    </div>
                  </div>

                  <div class="education_block_body">
                    <h4 class="bl-title">
                         </h4>
                  </div>

                  <div class="cources_info_style3 pb-2">
                    <ul>
                      <li class="d-flex align-items-center dos">
                        <mat-icon class="mr-2">event</mat-icon> Publicado:
                        10/02/2021
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-4 col-md-4 col-sm-4">
              <div class="singles_items">
                <div class="education_block_grid style_2">
                  <div class="education_block_thumb n-shadow banner">
                    <a>
                      <img
                        src="assets/img/edu_2.png"
                        class="img-fluid"
                        alt=""
                      />
                    </a>
                    <div class="cources_price">NOMBRE DE NOTICIA</div>
                    <div class="education_ratting d-flex align-items-center">
                      <mat-icon class="mr-2" color="accent">room</mat-icon>
                      DETALLE DE LA NOTICIA
                    </div>
                  </div>

                  <div class="education_block_body">
                    <h4 class="bl-title">
                       </h4>
                  </div>

                  <div class="cources_info_style3 pb-2">
                    <ul>
                      <li class="d-flex align-items-center dos">
                        <mat-icon class="mr-2">event</mat-icon> Publicado:
                        10/02/2021
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div style="margin-left: 32vw">
          <a class="btn btn-modern mt-4"
            >Ver más noticias...
            <span>
              <mat-icon>arrow_right_alt</mat-icon>
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div> -->
