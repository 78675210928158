import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { LoaderService } from './loader.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
//import swal from 'sweetalert2';
import { catchError, finalize, timeout } from 'rxjs/operators';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  private peticiones: HttpRequest<any>[] = [];

  constructor(
    public loaderService: LoaderService,
    private router: Router,
    // private modalService: NgbModal,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    this.peticiones.push(req);
    // console.log(req, 'req - ingreso al loader');

    this.loaderService.isLoading.next(true);
    return next.handle(req).pipe(
      catchError(this.manejarErrores),
      finalize(() => {
        this.removerPeticion(req);
        if (!this.peticiones.length) {
          this.loaderService.isLoading.next(false);
        }
      }
      ));
  }

  manejarErrores(error: HttpErrorResponse) {
    console.log(error, 'error interceptado');
    return throwError('error');
  }

  removerPeticion(req:any) {
    this.peticiones = this.peticiones.filter(peticion => peticion !== req);
  }
}
