import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { LocalStoreService } from "./local-store.service";
import { environment } from "./../../../environments/environment";
import { catchError, map } from "rxjs/operators";
import { Observable } from 'rxjs';

const httpOptions = {
  headers: new HttpHeaders({
    "Content-Type": "application/json",
    Accept: "application/json",
  }),
};

@Injectable({
  providedIn: "root",
})
export class ApiService {
  private baseUrl: string;
  private baseRecursos: string;
  headers: any;
  token: any;
  ls = window.localStorage;
  httpOptions: any;


  constructor(
    private http: HttpClient,
    private store: LocalStoreService
  ) {

    this.baseUrl = environment.backendApi;
    this.baseRecursos = environment.backend;
  }
  insertHead() {
    this.ls.getItem("unamToken");
    let data = this.store.getItem("unamToken");
    this.token = data["access_token"];
    if (data["access_token"]) {
      this.httpOptions = {
        headers: new HttpHeaders({
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.token,
        }),
      };
    }
  }
  getUser(data:any): Observable<any> {
    return this.http.post(`${this.baseUrl}/auth/login`, data);
  }

  getProfile() {
    this.insertHead();
    return this.http.get(`${this.baseUrl}/obtenerInfoCredencial`, httpOptions);
  }
  verificarLoggued(mod:any) {
    this.insertHead();
    return this.http.get(
      `${this.baseUrl}/verificarLogueo/${mod}`,
      this.httpOptions
    );
  }
  setToken(token: string): void {
    return localStorage.setItem("Bearer", token);
  }
  getToken() {
    return localStorage.getItem("Bearer");
  }

  isAuthenticated(): boolean {
    const token = this.getToken();
    if (token) {
      return true;
    }
    return false;
  }

  getCategorias() {
    return this.http.get(`${this.baseUrl}/cms/categorias/lista`)
  }
}
