import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class EmergentesService {
  backendApi: string
  constructor(private http: HttpClient) {
    this.backendApi = environment.backendApi;
  }

  getEmergentes() {
    const params = {bHabilitado: 1}
    return this.http.get(`${this.backendApi}/public/emergentes/lista`, {params})
  }


}
