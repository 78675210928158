import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  SimpleChanges,
} from '@angular/core';

import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { Swal2Service } from 'src/app/servicios/swal2.service';

import { ContactenosService } from  'src/app/servicios/contactenos.service';
import { ConfiguracionService } from 'src/app/servicios/configuracion.service';

@Component({
  selector: 'app-contactenos',
  templateUrl: './contactenos.component.html',
  styleUrls: ['./contactenos.component.scss']
})
export class ContactenosComponent implements OnInit {
  form!: FormGroup;
  //contacto: any;

  constructor(
    private formBuilder: FormBuilder,
    private swal2Service: Swal2Service,
    private ConfiguracionService: ConfiguracionService,
    private ContactenosService:ContactenosService,
  ) { }
  formConfiguracion!: FormGroup;
  configuracion: any = [];
  ngOnInit(): void {
    
    this.iniciarForm();
    //this.listIntereses();
    this.listConfiguracion();
  }

  iniciarForm(): void {  
    this.form = this.formBuilder.group({
      opcion: ['CONSULTAR'],
      iContId: [''],
      cContNombre: [''],
      cContCorreo: [''],
      cContAsunto: [''],
      cContMensaje: [''],
      cContMensajeEnviado: [''],
      cContMensajeRecibido: [''],
    });
    this.formConfiguracion = this.formBuilder.group({
      opcion: ['CONSULTAR'],
      valorBusqueda: [''],
      iConfId: [''],
      cConfTitulo: ['', Validators.required],
      cConfDescripcion: ['', Validators.required],
      cConfLogo: ['', Validators.required],
      cConfColor: ['', Validators.required],
      bConfHabilitado: ['', Validators.required],
      cConfHeader: ['', Validators.required],
      cConfHeaderLogo: ['', Validators.required],
      cConfHeaderColor: ['', Validators.required],
      bConfHeaderHabilitado: ['', Validators.required],
      cConfFooter: ['', Validators.required],
      cConfFooterLogo: ['', Validators.required],
      cConfFooterColor: ['', Validators.required],
      cConfFooterCopy: ['', Validators.required],
      bConfFooterHabilitado: ['', Validators.required],
      cConfPaginaAnterior: ['', Validators.required],
      cConfCorreo: ['', Validators.required],
      cConfDireccion: ['', Validators.required],
      cConfTelefono: ['', Validators.required],
      cConfHorario: ['', Validators.required],
      cConfAnio: ['', Validators.required],
      cConfYoutube: ['', Validators.required],
      cConfYoutubeLogo: ['', Validators.required],
      cConfYoutubeLink: ['', Validators.required],
      bConfYoutubeHabilitado: ['', Validators.required],
      cConfInstagram: ['', Validators.required],
      cConfInstagramLogo: ['', Validators.required],
      cConfInstagramLink: ['', Validators.required],
      bConfInstagramHabilitado: ['', Validators.required],
      cConftwiter: ['', Validators.required],
      cConftwiterLogo: ['', Validators.required],
      cConftwiterLink: ['', Validators.required],
      bConftwiterHabilitado: ['', Validators.required],
      cConfFacebook: ['', Validators.required],
      cConfFacebookLogo: ['', Validators.required],
      cConfFacebookLink: ['', Validators.required],
      bConfFacebookHabilitado: ['', Validators.required],
      cConfWhatsapp: ['', Validators.required],
      cConfWhatsappLogo: ['', Validators.required],
      cConfWhatsappLink: ['', Validators.required],
      bConfWhatsappHabilitado: ['', Validators.required],
      iEstado: ['', Validators.required],
      bHabilitado: ['', Validators.required],
    });
  }

  storeContactenos():void {
    this.form.controls.opcion.setValue('GUARDAR');

    if (this.form.valid) {
      this.ContactenosService.store(this.form.value).subscribe(
        (data: any) => {
          this.swal2Service.alertaToast(
            'Guardado',
            'Se guardó con éxito',
            'success'
          );
          this.form.reset();
          //this.listIntereses();
        },
        (error) => {
          this.swal2Service.alertaToast(
            'ATENCIÓN',
            error.error.mensaje,
            'warning'
          );

        }
      );
    } else {
      this.swal2Service.alertaToast(
        'ATENCIÓN',
        'Debe de Ingresar todos los campos',
        'warning'
      );
    }
  }

  sendContactenos():void {
    

    if (this.form.valid) {
      this.ContactenosService.notificar(this.form.value).subscribe(
        (data: any) => {
          this.swal2Service.alertaToast(
            'Enviado',
            'Se Envio el Mensaje con éxito',
            'success'
          );
          //this.form.reset();
          //this.listIntereses();
        },
        (error) => {
          this.swal2Service.alertaToast(
            'ATENCIÓN',
            error.error.mensaje,
            'warning'
          );

        }
      );
    } else {
      this.swal2Service.alertaToast(
        'ATENCIÓN',
        'Debe de Ingresar todos los campos',
        'warning'
      );
    }
  }

  listConfiguracion(): void {
    this.ConfiguracionService.list(this.formConfiguracion.value).subscribe(
      (resp: any) => {
        this.configuracion = [];
        this.configuracion = resp.data;
       
      },

      (error: any) => {
        
        this.swal2Service.alertaToast(
          'ATENCIÓN',
          error.error.mensaje,
          'warning'
        );
      }
    );
  }

}
