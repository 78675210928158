import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder,FormGroup } from '@angular/forms';
import { NoticiasService } from 'src/app/servicios/noticias.service';
import { environment } from 'src/environments/environment';
import { Swal2Service } from 'src/app/servicios/swal2.service';
import { Router, ActivatedRoute } from '@angular/router';
import { InteresesService } from 'src/app/servicios/intereses.service';
import SwiperCore, { Pagination } from "swiper";

// install Swiper modules
SwiperCore.use([Pagination]);
@Component({
  selector: 'app-detalle-noticias',
  templateUrl: './detalle-noticias.component.html',
  styleUrls: ['./detalle-noticias.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DetalleNoticiasComponent implements OnInit {
  iNotiId: any;
  backend = environment.backendStorage;
  form!: FormGroup;
  constructor(
    private activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private NoticiasService: NoticiasService,
    private swal2Service: Swal2Service,
    private InteresesService: InteresesService
  ) {
    this.iNotiId = null;
    
    this.activatedRoute.params.subscribe((params: any) => {
      this.iNotiId = params.iNotiId;
    });
    this.iniciarForm();
  }

  ngOnInit(): void {
    this.iniciarForm();
    this.listNoticias();
    this.listIntereses();
  }
  iniciarForm(): void {
    this.form = this.formBuilder.group({
      opcion: ['CONSULTAR'],
      iNotiId: [''],
      cNotiTitulo: [''],
      cNotiDescripcion: [''],
      cNotiImagen: [''],
      cNotiLink: [''],
      cNotiOrden: [''],
      iNotiVisitas: [''],
      iNotiAnio: [''],
      dNotiFechaCreacion: [''],
      dNotiFechaVigencia: [''],
      cUsuarioSis: [''],
    });
  }

  noticia: any = [];
  listNoticias(): void {
    this.form.controls.opcion.setValue('CONSULTARxiNotiId');
    this.form.controls.iNotiId.setValue(this.iNotiId);
    this.NoticiasService.list(this.form.value).subscribe(
      (resp: any) => {
        this.noticia = [];
        this.noticia = resp.data;
      },
      (error: any) => {
        //console.log(error)
        this.swal2Service.alertaToast(
          'ATENCIÓN',
          error.error.mensaje,
          'warning'
        );
      }
    );
  }

  interes:any=[];
  listIntereses(): void {
    this.form.controls.opcion.setValue('CONSULTAR');
    this.InteresesService.list(this.form.value).subscribe(
      (resp: any) => {
        this.interes = [];
        this.interes = resp.data;
      },
      (error: any) => {
        //console.log(error)
        // this.swal2Service.alertaToast(
        //   'ATENCIÓN',
        //   error.error.mensaje,
        //   'warning'
        // );
      }
    );
  }
  goToLink(url: any) {
    window.open("//" + url, '_blank');
  }

  controlledSwiper: any;
  setControlledSwiper(swiper: any) {
    this.controlledSwiper = swiper;
  }
}
