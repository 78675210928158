import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { InteresesService } from 'src/app/servicios/intereses.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-enlaces-interes',
  templateUrl: './enlaces-interes.component.html',
  styleUrls: ['./enlaces-interes.component.scss'],
})
export class EnlacesInteresComponent implements OnInit {
  backend = environment.backendStorage;
  isLoggedIn = false;
  username: any;
  titulo: any;

  imagen: any;

  datos: any = [];
  form!: FormGroup;
  interes:any=[];
  constructor(
    private formBuilder: FormBuilder,
    private InteresesService: InteresesService
  ) {}

  ngOnInit(): void {
    this.iniciarForm();
    this.listIntereses();
  }

  iniciarForm(): void {
    this.form = this.formBuilder.group({
      opcion: ['CONSULTAR'],
      iInteId: [''],
      cInteTitulo: [''],
      cInteDescripcion: [''],
      cInteImagen: [''],
      cInteLink: [''],
      cInteOrden: [''],
      cInteVisitas: [''],
      cInteAnio: [''],
      dInteFechaCreacion: [''],
      dInteFechaVigencia: [''],
      bInteHabilitado: [''],
      cUsuarioSis: [''],
    });
  }
  listIntereses(): void {
    this.form.controls.opcion.setValue('CONSULTAR');
    this.InteresesService.list(this.form.value).subscribe(
      (resp: any) => {
        this.interes = [];
        this.interes = resp.data;
      },
      (error: any) => {
        //console.log(error)
        // this.swal2Service.alertaToast(
        //   'ATENCIÓN',
        //   error.error.mensaje,
        //   'warning'
        // );
      }
    );
  }
}
